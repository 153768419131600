import React, {useState, useEffect} from 'react';
import axios from 'axios';

const Person = ({person}) => {
  return (
    <li>
      {person.name} ({person.number})
    </li>
  );
};

const Filter = props => {
  const {onChange, value} = props;
  return (
    <p>
      Filter by name: <input onChange={onChange} value={value} />
    </p>
  );
};

const PersonForm = props => {
  const {onSubmit, onNameChange, name, onNumberChange, number} = props;
  return (
    <form onSubmit={onSubmit}>
      <div>
        name: <input onChange={onNameChange} value={name} />
      </div>
      <div>
        number: <input onChange={onNumberChange} value={number} />
      </div>
      <div>
        <button type="submit">add</button>
      </div>
    </form>
  );
};

const Persons = props => {
  const {persons, nameFilter} = props;

  const rows = () => {
    if (nameFilter === '') {
      return persons.map(person => <Person key={person.id} person={person} />);
    } else {
      return persons
        .filter(person =>
          person.name.toLowerCase().includes(nameFilter.toLowerCase()),
        )
        .map(person => <Person key={person.id} person={person} />);
    }
  };
  return <ul>{rows()}</ul>;
};

const App = props => {
  const [persons, setPersons] = useState([]);
  const [newName, setNewName] = useState('');
  const [newNumber, setNewNumber] = useState('');
  const [nameFilter, setNameFilter] = useState('');

  useEffect(() => {
    axios.get('http://webapp1.kapsi.fi:3001/persons').then(response => {
      setPersons(response.data);
    });
  }, []);

  const handleNameChange = event => {
    setNewName(event.target.value);
  };

  const handleNumberChange = event => {
    setNewNumber(event.target.value);
  };

  const handleNameFilterChange = event => {
    setNameFilter(event.target.value);
  };

  const addPerson = event => {
    event.preventDefault();
    const alreadyExists =
      persons.find(person => person.name === newName) != null;
    if (alreadyExists) {
      alert(`Person with name ${newName} already added to phone book!`);
      return;
    }
    setPersons(
      persons.concat({
        id: persons.length + 1,
        name: newName,
        number: newNumber,
      }),
    );
    setNewNumber('');
    setNewName('');
  };

  return (
    <div>
      <h1>Phonebook</h1>
      <h2>Add a new contact</h2>
      <PersonForm
        onSubmit={addPerson}
        name={newName}
        number={newNumber}
        onNameChange={handleNameChange}
        onNumberChange={handleNumberChange}
      />
      <h2>Numbers</h2>
      <Filter onChange={handleNameFilterChange} value={nameFilter} />
      <Persons persons={persons} nameFilter={nameFilter} />
    </div>
  );
};

export default App;
